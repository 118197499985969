import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  fetchModalContent() {
  	const modal = document.querySelector(this.element.getAttribute("data-list-modal-id"));
  	const modalLoadingContent = modal.querySelector('.list-modal-loading').innerHTML;
  	const modalTitleContent = this.element.getAttribute("data-list-modal-title")

  	modal.querySelector('.modal-title').innerHTML = modalTitleContent;
  	modal.querySelector('turbo-frame#player').innerHTML = modalLoadingContent;
 	new bootstrap.Modal(modal).show();
  }
}
